* { box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root {
  height: 100%;
}

.full-width { width: 100%; }

.full-size { width: 100%; height: 100%; }
